import axios from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import BuyerHeader from '../Components/Header/BuyerHeader';
import LoadingIcon from '../Components/LoadingIcon';
import SearchInput from '../Components/Search';
import Spinner from '../Components/Spinner';
import '../Styles/Selling/Selling.css';

function Selling() {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [purchasingData, setPurchasingData] = useState([]);
  const [stockStats, setStockStats] = useState({
    totalInStock: 0,
    totalOutOfStock: 0,
  });
  const [searchItem, setSearchItem] = useState({
    make: '',
    network: '',
    model: '',
    imei: '',
  });

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  const fetchStockStats = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/stock/stat?shop=${loggedInUserShopId}`
      );
      const stats = response.data.data[0];
      setStockStats(stats);
      setLoading(false);
    } catch (error) {
      console.error('Error while fetching stock stats: ', error);
    }
  };

  const debouncedFetchPurchasedData = debounce(() => {
    fetchPurchasingData();
  }, 500);

  const handleSearch = () => {
    debouncedFetchPurchasedData();
  };

  useEffect(() => {
    handleSearch();
  }, [searchItem]);

  const fetchPurchasingData = async () => {
    // Construct the query parameters based on selected search parameters
    const queryParams = [];
    for (const key in searchItem) {
      if (searchItem[key]) {
        queryParams.push(`${key}=${searchItem[key]}`);
      }
    }

    // Join query parameters with '&'
    const queryString = queryParams.join('&');

    const apiUrl = queryString
      ? `/api/v1/purchasing?${queryString}&shop=${loggedInUserShopId}`
      : `/api/v1/purchasing?shop=${loggedInUserShopId}`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${apiUrl}`
      );
      setPurchasingData(response.data.data.filter((el) => el.stock > 0));
    } catch (error) {
      console.error('Error fetching purchasing data:', error);
    }
  };

  useEffect(() => {
    fetchPurchasingData();
    fetchStockStats();
  }, []);

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return (
    <>
      <BuyerHeader navs='5' />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='selling-container'>
          <div className='selling-row'>
            <SearchInput
              placeholder='Manufactured By'
              searchTerm={searchItem.make}
              onChange={(e) =>
                setSearchItem({ ...searchItem, make: e.target.value })
              }
            />
            <SearchInput
              placeholder='Network'
              searchTerm={searchItem.network}
              onChange={(e) =>
                setSearchItem({ ...searchItem, network: e.target.value })
              }
            />
            <SearchInput
              placeholder='Model Name'
              searchTerm={searchItem.model}
              onChange={(e) =>
                setSearchItem({ ...searchItem, model: e.target.value })
              }
            />
            <SearchInput
              placeholder='IMEI'
              searchTerm={searchItem.imei}
              onChange={(e) =>
                setSearchItem({ ...searchItem, imei: e.target.value })
              }
            />
            <button className='search-button' onClick={handleSearch}>
              Search
            </button>
          </div>
          <div className='row'>
            {/* <ListingBox
              text="Available Stock"
              number={stockStats.totalInStock}
              color="#FF7C5C"
              textcolor="#FF7C5C"
            /> */}
          </div>
          <div className='selling-listing-table'>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 20,
                }}
              >
                <LoadingIcon />
              </div>
            ) : purchasingData && purchasingData.length > 0 ? (
              <table className='blueTable'>
                <thead>
                  <tr>
                    {' '}
                    <th>Invoice</th>
                    <th>Barcode</th>
                    <th>Manufacturer</th>
                    <th>Model</th>
                    <th>IMEI</th>
                    {/* <th>Network</th> */}
                    <th>Accessories</th>
                    <th>Selling Price</th>
                  </tr>
                </thead>
                <tbody>
                  {purchasingData.map((item) => (
                    <tr key={item._id}>
                      <td>{item.invoice}</td>
                      <td>{item.barCode}</td>
                      {/* <td>£{item.price}</td> */}
                      <td>{item.make}</td>
                      <td>{item.model}</td>
                      <td className='color'>{item.imei}</td>
                      {/* <td>{item.network}</td> */}
                      <td>{item.accessories ? 'Yes' : 'No'}</td>
                      <td>£{item.sellingPrice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                <table className='blueTable'>
                  <thead>
                    <tr>
                      <th>Manufacturer</th>
                      <th>Model</th>
                      <th>IMEI</th>
                      <th>Network</th>
                      <th>Accessories</th>
                      <th>Selling Price</th>
                      <th>Invoice</th>
                    </tr>
                  </thead>
                </table>
                <div className='no-data'>
                  <p>No data available</p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Selling;
