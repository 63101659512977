import axios from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import BuyerHeader from '../Components/Header/BuyerHeader';
import Pagination from '../Components/Pagination';
import SearchInput from '../Components/Search';
import Spinner from '../Components/Spinner';
import we from '../Images/we.png';
import '../Styles/CheckPrice/CheckPrice.css';

function PriceCheck() {
  const [stockList, setStockList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();

  const itemsPerPage = 5;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [searchParam, setSearchParam] = useState({
    vender: '',
    model: '',
    partName: '',
  });

  const loggedInUserShopId =
    JSON.parse(localStorage.getItem('user'))?._id ?? undefined;

  const debouncedFetchStockData = debounce(() => {
    fetchStockData();
  }, 500);

  const handleSearch = () => {
    debouncedFetchStockData();
  };

  useEffect(() => {
    handleSearch();
  }, [searchParam, currentPage]);

  const fetchStockData = async () => {
    const pageNumber = currentPage;
    const recordsPerPage = itemsPerPage;
    // Construct the query parameters based on selected search parameters
    const queryParams = [];

    // Include the search terms in the query parameters
    if (searchParam.vender) {
      queryParams.push(`vender=${searchParam.vender}`);
    }
    if (searchParam.model) {
      queryParams.push(`model=${searchParam.model}`);
    }
    if (searchParam.partName) {
      queryParams.push(`partName=${searchParam.partName}`);
    }

    // Join the query parameters with '&' to form the complete query string
    const queryString = queryParams.join('&');

    // Construct the API URL with query parameters
    const apiUrl = queryString
      ? `stock?isAccessory=${true}&shop=${loggedInUserShopId}&${queryString}`
      : `stock?isAccessory=${true}&shop=${loggedInUserShopId}&${queryString}`;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${apiUrl}`
      );

      let stockedItems = response.data.data.stockList.filter(
        (el) => el.itemsInStock > 0
      );

      setStockList(stockedItems);
      setTotalItems(stockedItems.length);
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };

  useEffect(() => {
    fetchStockData();
  }, []); // Fetch data on component mount

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <BuyerHeader navs={1} />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='price-check-container'>
          <div className='price-check-row'>
            <div className='row-left'>
              <SearchInput
                placeholder='Search by Manufactured By'
                value={searchParam.vender}
                onChange={(e) =>
                  setSearchParam({ ...searchParam, vender: e.target.value })
                }
              />
              <SearchInput
                placeholder='Search by Model'
                value={searchParam.model}
                onChange={(e) =>
                  setSearchParam({ ...searchParam, model: e.target.value })
                }
              />
              <SearchInput
                placeholder='Search by Part Name'
                value={searchParam.partName}
                onChange={(e) =>
                  setSearchParam({ ...searchParam, partName: e.target.value })
                }
              />
            </div>
            <div>

              <button className='search-button' onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
          <div className='price-check-table'>
            {stockList && stockList.length > 0 ? (
              <>
                <table class='blueTable'>
                  <thead>
                    <tr>
                      <th className='fixed'>Image</th>
                      <th>Part Name</th>
                      <th>Manufactured By</th>
                      <th>Model</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockList?.map((item) => (
                      <tr key={item._id}>
                        <td>
                          <img src={we} alt='Product' />
                        </td>
                        <td>{item.partName}</td>
                        <td>{item.vender}</td>
                        <td>{item.model}</td>
                        <td>{item.price}</td>
                        <td>{item.comments}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <table class='blueTable'>
                  <thead>
                    <tr>
                      <th className='fixed'>Image</th>
                      <th>Part Name</th>
                      <th>Manufacturer</th>
                      <th>Model</th>
                      <th>Price</th>
                      <th className='longer'>Comments</th>
                    </tr>
                  </thead>
                </table>
                <div className='no-data'>
                  <p>No data available</p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PriceCheck;
